<template>
  <v-row justify="center">
    <v-col
      cols="12"
      md="10"
      lg="8"
    >
      <page-title
        title="FAQs"
        subtitle="Frequently asked questions about Airfield List"
      />
      <v-expansion-panels
        v-model="openPanels"
        accordion
        multiple
        class="mt-8"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            What is Airfield List?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <p>
              A crowd sourced and curated list of amazing airfields to fly to.
              Easily filterable and searchable, to give hour builders and
              adventure pilots somewhere new to visit.
            </p>
            <p>
              Save your list of airfields to visit, try to visit them all, and
              check visited airfields off as you visit them.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Why another list of airfields?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <p>
              <a href="/">Airfield List</a> is trying to do something a little
              different, we want you to discover new places to fly, perhaps
              somewhere you would never have considered before.
            </p>
            <p>
              Maybe you're hour building and want to find somewhere new to fly,
              or practice different approaches.
            </p>
            <p>
              Or an adventurer looking to make discoveries, or honing your
              skills on short farmstrips.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            What is coming next?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <p>Here's some things we're working on adding to Airfield List:</p>
            <ul class="mb-5">
              <li>
                More airfields, we're adding airfields every day but if your
                favourite is missing please
                <router-link to="/add">
                  add it to the list here
                </router-link>
              </li>
              <li>
                Airfield Score&#8482;, a way to rank airfields based on
                different data points.
              </li>
              <li>
                Random airfield to visit
                <v-icon
                  right
                  small
                >
                  mdi-dice-3
                </v-icon>
              </li>
              <li>
                Random airfield to visit from My List
                <v-icon
                  right
                  small
                >
                  mdi-dice-6
                </v-icon>
              </li>
              <li>
                Rating and review system
                <v-icon
                  right
                  small
                >
                  mdi-star
                </v-icon>
                <v-icon small>
                  mdi-star
                </v-icon>
                <v-icon small>
                  mdi-star-outline
                </v-icon>
              </li>
              <li>
                Filter for airfields with instrument approaches
                <v-icon
                  right
                  small
                >
                  mdi-navigation-variant
                </v-icon>
              </li>
              <li>
                Airparks you can live on, or airfields you build a hangar home
                on
                <v-icon
                  right
                  small
                >
                  mdi-home-group
                </v-icon>
              </li>
            </ul>
            <p>
              and more, but if there's something you think would be good, please
              let us know on Twitter
              <a
                href="https://twitter.com/AirfieldList"
                target="_blank"
                rel="noopener noreferrer"
              >@AirfieldList</a>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            How many people use Airfield List?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <p>
              Currently there are about 20 people visiting the list per day,
              that's about <b>600 visits per month</b>, and growing rapidly!
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Where do you source the airfield data from?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <p>
              It started with a public spreadsheet to crowdsource the best
              airfields from pilots around the UK. This was a great start and
              showed that people were willing to help build a list for the
              community.
            </p>
            <p>
              The spreadsheet held about 40 airfields, which was a great start,
              but we needed something a little more refined, where we can all
              contribute to and build a better list with ease.
            </p>
            <p>
              To tackle this the Airfield List website was started so we can
              add, edit, and rate airfields on various criteria, using clever
              robots to remove the outliers, and score the airfields based on
              your what you're looking for.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Can I write an article about Airfield List and use your data?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <p>
              Sure! You can make screenshots of Airfield List, and use the data,
              as long as you reference us as "Airfield List" (with space in
              between) and link back to the website with
              <a href="https://airfieldlist.com">https://airfieldlist.com</a>
            </p>
            <p>Thank you! :)</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Can I promote my product or service on Airfield List?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <p>
              Sure, we will consider partnerships, like sponsorships and
              integrations into the site if they match our brand. Think
              insurance for pilots/aircraft, hangerage at your airfield, or
              something else aviation related.
            </p>
            <p>
              If you're interested, please email us
              <a
                href="mailto:hello@airfieldlist.com"
                target="_blank"
                rel="noopener"
              >hello@airfieldlist.com</a>, or tweet or DM us
              <a
                href="https://twitter.com/airfieldlist"
                target="_blank"
                rel="noopener noreferrer"
              >@airfieldlist</a>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            My question isn't listed here, how can I contact you?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <p>
              If you have another question please email us
              <a
                href="mailto:hello@airfieldlist.com"
                target="_blank"
                rel="noopener"
              >hello@airfieldlist.com</a>, or Tweet or DM us
              <a
                href="https://twitter.com/airfieldlist"
                target="_blank"
                rel="noopener noreferrer"
              >@airfieldlist</a>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "Faqs",

  components: {
    PageTitle,
  },

  data() {
    return {
      openPanels: [0],
    };
  },
};
</script>
